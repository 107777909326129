<template>
    <div class="report-filter">
        <transition name="fade">
            <div class="sliderBg" @click="$emit('update:ctrlFilterReport', false)"></div>
        </transition>
        <transition name="slider-customer">
            <div class="sliderBody">
                <!-- 选择模板（仅能查询自己收到的日志） -->
                <div class="filter-key">{{$t('mx_mobile.Log.1585793933545')}}</div>
                <div class="name-group">
                    <span class="temp-cell" :class="tempReportFindData.paperId === item.paperId ? 'active' : ''" v-for="(item, index) in lookNameList" :key="index" @click.stop="filterByPaperId(item.paperId)">{{item.name}}</span>
                </div>
                <!-- 按发送人筛选 -->
                <div class="filter-key">{{$t('mx_mobile.Log.1585793651779')}}</div>
                <div class="add-ctId">
                    <div class="ctId-list" v-if="tempReportFindData.ctId" @click="tempReportFindData.ctId = ''">
                        <i class="icon iconfont icon-my"></i>
                        <div class="send_name">{{getRealName(tempReportFindData.ctId)}}</div>
                    </div>
                    <i class="iconfont icon-add-round" @click.stop="showCtIdPop = true"></i>
                </div>
                <!-- 日 -->
                <div class="start-time" v-if="tempReportFindData.paperType === 1">
                    <!-- 发送时间 -->
                    <span class="_span">{{$t('mx_mobile.Log.1585793909467')}}</span>
                    <input type="date" id="startT" class="input__time" required v-model="scopeRange" />
                </div>
                <!-- 周 -->
                <template v-else-if="tempReportFindData.paperType === 2">
                    <div class="start-time">
                        <!-- 发送时间 -->
                        <span class="_span">{{$t('mx_mobile.Log.1585793909467')}}</span>
                        <input type="week" id="startT" class="input__time" required v-model="scopeRange" />
                    </div>
                </template>
                <!-- 月 -->
                <div class="start-time" v-else-if="tempReportFindData.paperType === 3">
                    <!-- 发送时间 -->
                    <span class="_span">{{$t('mx_mobile.Log.1585793909467')}}</span>
                    <input type="month" id="startT" class="input__time" v-model="scopeRange" />
                </div>
                <div class="handler-btn">
                    <!-- 取消 -->
                    <van-button @click.stop="exeFilter(1)" class="cus-btn" type="default">{{$t('mx_mobile.Log.1585194780650')}}</van-button>
                    <!-- 确定 -->
                    <van-button @click.stop="exeFilter(2)" class="cus-btn" type="danger">{{$t('mx_mobile.Log.1585793676826')}}</van-button>
                </div>
            </div>
        </transition>
        <van-popup v-model="showCtIdPop" @click-overlay="onCancelCtId" :close-on-click-overlay="false" position="bottom">
            <van-picker show-toolbar title="" :columns="userList" value-key="realName" @cancel="onCancelCtId" @confirm="onConfirmCtId" />
        </van-popup>
    </div>
</template>

<script>
import { formatValidTime, getMonDayAndSunDay, getWeekForDay, getDayForWeek } from '@/libs/utils.js'
export default {
    name: 'FilterReport',
    props: {
        lookNameList: {
            type: Array
        },
        reportFindData: {
            type: Object
        },
        userList: {
            type: Array
        }
    },
    components: {},
    data() {
        return {
            showCtIdPop: false,
            tempReportFindData: {},
            scopeRange: ''
        }
    },
    computed: {},
    watch: {
        'tempReportFindData.paperType': {
            handler(newV, oldV) {
                if (newV !== oldV) {
                    if (newV != this.reportFindData.paperType) {
                        // 切换日志类型，初始化日期
                        this.scopeRange = ''
                        if (newV == 1) {
                            this.tempReportFindData.scope = formatValidTime(new Date().getTime(), 'yyyy-MM-dd')
                            this.scopeRange = this.tempReportFindData.scope
                        } else if (newV == 2) {
                            this.tempReportFindData.scope = getMonDayAndSunDay(formatValidTime(new Date().getTime(), 'yyyy-MM-dd'))
                            this.scopeRange = getWeekForDay(formatValidTime(new Date().getTime(), 'yyyy-MM-dd')) // '2019-W45'
                        } else {
                            this.tempReportFindData.scope = formatValidTime(new Date().getTime(), 'yyyy-MM')
                            this.scopeRange = this.tempReportFindData.scope
                        }
                    } else {
                        // 延用传递过来的日期
                        if (newV == 1) {
                            this.scopeRange = this.reportFindData.scope
                        } else if (newV == 2) {
                            this.scopeRange = getWeekForDay(this.reportFindData.scope.split(',')[0])
                        } else {
                            this.scopeRange = this.tempReportFindData.scope
                        }
                    }
                }
            },
            deep: true
        }
    },
    created() {
        this.tempReportFindData = JSON.parse(JSON.stringify(this.reportFindData))
        // 周报时间格式转化
        if (this.tempReportFindData.paperType === 2) {
            this.scopeRange = getWeekForDay(this.tempReportFindData.scope.split(',')[0])
        }
    },
    mounted() {
    },
    methods: {
        onCancelCtId() {
            this.showCtIdPop = false
        },
        onConfirmCtId(val, index) {
            this.tempReportFindData.ctId = val.ctId
            this.showCtIdPop = false
        },
        getRealName(ctId) {
            try {
                if (ctId && this.userList.length > 0) {
                    let _p = this.userList.filter(v => v.ctId === ctId)
                    return _p[0].realName
                } else {
                    return ''
                }
            } catch (err) {
                console.log(err)
                return ''
            }
        },
        filterByPaperId(paperId) {
            this.tempReportFindData.paperId = paperId
            this.tempReportFindData.ctId = ''
            this.tempReportFindData.paperType = this.lookNameList.filter(v => paperId === v.paperId)[0].paperType
        },
        exeFilter(n) {
            try {
                // n 1:重置, 2:确定筛选
                if (n === 1) {
                    this.$emit('update:ctrlFilterReport', false)
                    return
                }
                if (this.tempReportFindData.paperType === 1 || this.tempReportFindData.paperType === 3) {
                    this.tempReportFindData.scope = this.scopeRange
                } else if (this.tempReportFindData.paperType === 2) {
                    // 根据周获取当前周的周一和周日日期
                    this.tempReportFindData.scope = getMonDayAndSunDay(getDayForWeek(this.scopeRange)).join(',')
                }
                this.$emit('update:ctrlFilterReport', false)
                this.$emit('getInitData', this.tempReportFindData)
            } catch (err) {
                console.log(err)
            }
        }
    },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
